import Image from "next/image";
import QRCode from "react-qr-code";
import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import Link from "next/link";
import useOneLinkUrl from "hooks/useOneLinkUrl";
import { Fragment, MouseEventHandler } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Trans, t } from "@lingui/macro";
import { useState, useEffect } from "react";
import { useFeedback } from "hooks/feedbackContext";
import { Square2StackIcon } from "@heroicons/react/24/outline";
import { FaFacebookSquare, FaWhatsapp } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { useRouter } from "next/router";
import { ISideBarTopicsProps } from "@components/sidebar/side-bar-topics";

type DialogProps<T> = { closeDialog: MouseEventHandler } & T;
type DialogComponent<N> = React.FunctionComponent<DialogProps<N>>;

export enum DialogName {
  bookmark,
  share,
  moreTopics,
  viewableVideo,
  comment,
  download,
}

type ShareDialogProps = {
  id?: string;
  imageUrl?: string;
  title?: string;
  publisherName?: string;
  url?: string;
  headerComp?: JSX.Element | string;
  ge15Page?: boolean;
  hideCopy?: boolean;
};

type BookmarkDialogProps = {};

type MoreTopicsDialogProps = {};

type ViewableVideoDialogProps = {};

type DownloadProps = {};

type CommentDialogProps = {};

interface TriggerDialogMap {
  [DialogName.bookmark]: BookmarkDialogProps;
  [DialogName.share]: ShareDialogProps;
  [DialogName.moreTopics]: MoreTopicsDialogProps;
  [DialogName.viewableVideo]: ViewableVideoDialogProps;
  [DialogName.comment]: CommentDialogProps;
  [DialogName.download]: DownloadProps;
}

export const appDialogCategories: ISideBarTopicsProps[] = [
  // {
  //   key: "GE15",
  //   label: <Trans>GE15</Trans>,
  //   emoji: "🗳",
  // },
  {
    key: "Food",
    label: <Trans>Food</Trans>,
    emoji: "🍔",
  },
  {
    key: "Health",
    label: <Trans>Health</Trans>,
    emoji: "💪",
  },
  {
    key: "Animals",
    label: <Trans>Animals</Trans>,
    emoji: "🐱",
  },
  {
    key: "Travel",
    label: <Trans>Travel</Trans>,
    emoji: "✈️",
  },
  {
    key: "Money",
    label: <Trans>Money</Trans>,
    emoji: "💰",
  },
  {
    key: "Entertainment",
    label: <Trans>Entertainment</Trans>,
    emoji: "🎬",
  },
  {
    key: "Deals",
    label: <Trans>Deals</Trans>,
    emoji: "🛍️",
  },
  {
    key: "Business",
    label: <Trans>Business</Trans>,
    emoji: "📈",
  },
  {
    key: "Property",
    label: <Trans>Property</Trans>,
    emoji: "🏠",
  },
];

const Bookmark: DialogComponent<BookmarkDialogProps> = ({ closeDialog }) => {
  const mobileUrl = useOneLinkUrl("https://link.luminews.my/ewC9/bookmark");

  return (
    <div id="dialog-popup">
      <div className="flex justify-end">
        <div className="cursor-pointer pb-3 text-right" onClick={closeDialog}>
          <XMarkIcon className="h-5 w-5 text-primary3" id="close-dialog" />
        </div>
      </div>
      <Dialog.Title
        as="h3"
        className="font-poppins text-3xl font-bold text-gray-900"
      >
        <Trans>You found an app feature!</Trans>
      </Dialog.Title>
      <div className="mt-2">
        <p className="font-sen text-lg font-normal text-gray-500">
          <Trans>
            Switch to the Lumi News app to bookmark and share articles
          </Trans>
        </p>
        <div className="block py-8 lg:hidden">
          <Link href={mobileUrl}>
            <a className="rounded-2xl bg-primary p-4 font-sen text-white">
              <Trans>Switch to the app</Trans>
            </a>
          </Link>
        </div>
        <div className="mt-3 hidden flex-row items-center rounded-lg border border-text-lightest bg-white px-5 py-3 lg:flex">
          <QRCode value={"https://link.luminews.my/ewC9/bookmark"} size={56} />
          <p className="ml-4 flex-1 text-center font-poppins font-bold text-text-darkest">
            <Trans>👈 Scan the QR code to download</Trans>
          </p>
        </div>
      </div>
    </div>
  );
};

const Share: DialogComponent<ShareDialogProps> = ({
  closeDialog,
  id = "",
  imageUrl = "",
  title = "",
  publisherName = "",
  url = "",
  headerComp = null,
  ge15Page,
  hideCopy,
}) => {
  const { query } = useRouter();
  const utmMedium = "utm_medium=web_share_button";
  const utmSource = "";
  // Object.keys(query).length != 0 ? "&utm_source=web" : "?utm_source=web";
  const utmCampaign = "";
  // `&utm_campaign=news-stack-${topicToDash}`;
  const utm = `?${utmMedium}${utmSource}${utmCampaign}`;
  const fullLink = url + utm;

  const copyToClipboard = async (text) => {
    try {
      navigator.clipboard
        ?.writeText(`${url}${utm}`)
        ?.then(() => enqueueFeedback(t`Link copied`));
    } catch (err) {
      // Fallback method for older browsers or unsupported environments
      const tempTextarea = document.createElement("textarea");
      tempTextarea.value = text;
      document.body.appendChild(tempTextarea);
      tempTextarea.select();
      document.execCommand("copy");
      document.body.removeChild(tempTextarea);
      enqueueFeedback(t`Link copied`);
    }
  };

  const onCopy = () => {
    const textToCopy = `${url}${utm}`;
    copyToClipboard(textToCopy);
  };

  const { enqueueFeedback } = useFeedback();

  return (
    <div className="relative text-left font-sen">
      <div className="flex justify-between">
        <Dialog.Title
          as="h4"
          className="mb-2 font-poppins text-xl font-bold text-gray-900"
        >
          {ge15Page ? (
            <Trans>Share this page</Trans>
          ) : (
            <Trans>Share this article</Trans>
          )}
        </Dialog.Title>
        <div className="cursor-pointer pb-3 text-right" onClick={closeDialog}>
          <XMarkIcon className="h-5 w-5 text-primary3" />
        </div>
      </div>
      {headerComp ? (
        headerComp
      ) : (
        <div className="mt-3 flex items-center">
          <div className="relative h-[100px] w-[100px]">
            <Image
              className="rounded-lg "
              src={imageUrl || ""}
              alt={title}
              layout="fill"
              objectFit="cover"
            />
          </div>
          <div className="ml-3 flex flex-1 flex-col">
            <p className=" text-sm font-light text-text-dark">
              {publisherName}
            </p>
            <p className="mt-2 font-poppins text-sm font-bold">{title}</p>
          </div>
        </div>
      )}
      <div className="mt-4 flex flex-col space-y-3 ">
        <a
          href={`https://www.facebook.com/dialog/feed?app_id=1194404434408598&display=popup&link=${encodeURIComponent(
            fullLink + "&utm_source=facebook"
          )}`}
          target="blank"
          className="btn rounded-lg bg-[#4267B2] py-3 pl-4 text-left text-white"
        >
          <div className="flex flex-row items-center">
            <FaFacebookSquare className="mr-2" size={16} />
            <Trans>Share on Facebook</Trans>
          </div>
        </a>
        <a
          href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
            fullLink + "&utm_source=x"
          )}`}
          target="blank"
          className="btn rounded-lg bg-[#0C85D0] py-3 pl-4 text-left text-white"
        >
          <div className="flex flex-row items-center">
            <FaSquareXTwitter className="mr-2" size={16} />
            <Trans>Share on Twitter</Trans>
          </div>
        </a>
        <a
          href={`https://wa.me/?text=${encodeURIComponent(
            fullLink + "&utm_source=whatsapp"
          )}`}
          target="blank"
          className="btn rounded-lg bg-green py-3 pl-4 text-left text-white"
          data-action="share/whatsapp/share"
        >
          <div className="flex flex-row items-center">
            <FaWhatsapp className="mr-2" size={16} />
            <Trans>Share via WhatsApp</Trans>
          </div>
        </a>
      </div>

      {!hideCopy && (
        <div className="mt-4">
          <p>
            <Trans>Or share the link below:</Trans>
          </p>
          <div
            onClick={onCopy}
            className="flex cursor-pointer items-center justify-between space-x-2 rounded-lg bg-off-white p-4"
          >
            <span className="no-scrollbar overflow-scroll">{url}</span>
            <Square2StackIcon className="h-6 w-5 shrink-0 text-primary3" />
          </div>
        </div>
      )}
    </div>
  );
};

const MoreTopics: DialogComponent<MoreTopicsDialogProps> = ({
  closeDialog,
}) => (
  <div className="z-20">
    <div className="flex justify-end pb-4">
      <div
        className="cursor-pointer text-right"
        onClick={closeDialog}
        id="close-dialog"
      >
        <XMarkIcon className="h-5 w-5 text-primary3" />
      </div>
    </div>
    <Dialog.Title
      as="h2"
      className="mb-4 font-poppins text-3xl font-bold text-gray-900"
    >
      <Trans>You found an app feature!</Trans>
    </Dialog.Title>
    <div className="mt-2">
      <p className="font-sen text-lg font-normal text-gray-500">
        <Trans>Use the app to explore the full range of topics</Trans>
      </p>
    </div>
    <div className="my-6">
      <div className="flex flex-wrap justify-center">
        {appDialogCategories.map(({ label, emoji }, index) => (
          <div
            key={`topic-${index}`}
            className="mb-2 mr-2 flex flex-row items-center space-x-1 rounded-2xl bg-secondary px-4 py-2"
          >
            <p className="text-center font-sen text-sm text-primary2">
              {label} {emoji}
            </p>
          </div>
        ))}
      </div>
      <p className="mt-4 font-sen text-text-medium">
        <Trans>and many more!</Trans>
      </p>
    </div>
    <div className="block py-8 lg:hidden">
      <Link href="https://link.luminews.my/ewC9/switchtoapp">
        <a className="rounded-2xl bg-primary p-4 font-sen text-white">
          <Trans>Switch to the app</Trans>
        </a>
      </Link>
    </div>
    <div className="mt-3 hidden flex-row items-center rounded-lg border border-text-lightest bg-white px-5 py-3 lg:flex">
      <QRCode value={"https://link.luminews.my/ewC9/switchtoapp"} size={56} />
      <p className="ml-4 flex-1 text-center font-poppins font-bold text-text-darkest">
        <Trans>👈 Scan the QR code to download</Trans>
      </p>
    </div>
  </div>
);

const ViewableVideo: DialogComponent<ViewableVideoDialogProps> = ({
  closeDialog,
}) => (
  <div className="z-20">
    <div className="flex justify-end pb-4">
      <div
        className="cursor-pointer text-right"
        onClick={closeDialog}
        id="close-dialog"
      >
        <XMarkIcon className="h-5 w-5 text-primary3" />
      </div>
    </div>
    <div className="flex space-x-4">
      <Dialog.Title as="h2" className="my-auto">
        <p className="font-poppins text-3xl font-bold text-gray-900">
          <Trans>Continue in app</Trans>
        </p>
        <div className="mt-2">
          <p className="font-sen text-lg font-normal text-gray-500">
            <Trans>Switch to the Lumi News app for this exclusive video</Trans>
          </p>
          <div className="block py-8">
            <Link href="https://link.luminews.my/ewC9/switchtoapp">
              <a className="rounded-2xl bg-primary p-4 font-sen text-white">
                <Trans>Take me there</Trans>
              </a>
            </Link>
          </div>
        </div>
      </Dialog.Title>

      <div className="hidden rounded-lg bg-off-white p-4 md:block">
        <div className="rounded-lg bg-white p-2">
          <QRCode
            value={"https://link.luminews.my/ewC9/switchtoapp"}
            size={300}
          />
        </div>
        <p className="my-2 text-center font-poppins font-bold text-text-darkest">
          <Trans>Scan the QR code to download</Trans>
        </p>
        <div className="flex justify-around">
          <img src="/three-qrs.png" alt="QR Images" width={350} height={20} />
        </div>
      </div>
    </div>
  </div>
);
const Download: DialogComponent<DownloadProps> = ({ closeDialog }) => (
  <div className="z-20">
    <div className="flex justify-end pb-4">
      <div
        className="cursor-pointer text-right"
        onClick={closeDialog}
        id="close-dialog"
      >
        <XMarkIcon className="h-5 w-5 text-primary3" />
      </div>
    </div>
    <div className="flex space-x-4 text-center">
      <Dialog.Title as="h2" className="my-auto w-full">
        <p className="font-poppins text-3xl font-bold text-gray-900">
          <Trans>It's better on the app ✨</Trans>
        </p>
        <div className="mt-2">
          <p className="font-sen text-lg font-normal text-gray-500">
            <Trans>Download the app to get the full reading experience.</Trans>
          </p>
          <div className="block py-8 md:hidden">
            <Link href="https://link.luminews.my/ewC9/switchtoapp">
              <a className="rounded-2xl bg-primary p-4 font-sen text-white">
                <Trans>Take me there</Trans>
              </a>
            </Link>
          </div>
        </div>
      </Dialog.Title>

      <div className="hidden rounded-lg bg-off-white p-4 md:block">
        <div className="rounded-lg bg-white p-2">
          <QRCode
            value={"https://link.luminews.my/ewC9/switchtoapp"}
            size={300}
          />
        </div>
        <p className="my-2 text-center font-poppins font-bold text-text-darkest">
          <Trans>Scan the QR code to download</Trans>
        </p>
        <div className="flex justify-around">
          <img src="/three-qrs.png" alt="QR Images" width={350} height={20} />
        </div>
      </div>
    </div>
  </div>
);

const Comment: DialogComponent<CommentDialogProps> = ({ closeDialog }) => (
  <div className="z-20">
    <div className="flex justify-end pb-4">
      <div
        className="cursor-pointer text-right"
        onClick={closeDialog}
        id="close-dialog"
      >
        <XMarkIcon className="h-5 w-5 text-primary3" />
      </div>
    </div>
    <div className="flex space-x-4">
      <Dialog.Title as="h2" className="my-auto">
        <p className="font-poppins text-3xl font-bold text-gray-900">
          <Trans>Continue in app</Trans>
        </p>
        <div className="mt-2">
          <p className="font-sen text-lg font-normal text-gray-500">
            <Trans>Switch to the Lumi News app to leave a comment</Trans>
          </p>
          <div className="block py-8">
            <Link href="https://link.luminews.my/ewC9/switchtoapp">
              <a className="rounded-2xl bg-primary p-4 font-sen text-white">
                <Trans>Take me there</Trans>
              </a>
            </Link>
          </div>
        </div>
      </Dialog.Title>

      <div className="hidden rounded-lg bg-off-white p-4 md:block">
        <div className="rounded-lg bg-white p-2">
          <QRCode
            value={"https://link.luminews.my/ewC9/switchtoapp"}
            size={300}
          />
        </div>
        <p className="my-2 text-center font-poppins font-bold text-text-darkest">
          <Trans>Scan the QR code to download</Trans>
        </p>
        <div className="flex justify-around">
          <img src="/three-qrs.png" alt="QR Images" width={350} height={20} />
        </div>
      </div>
    </div>
  </div>
);

function Content<N extends DialogName>({
  closeDialog,
  dialogData,
}: {
  closeDialog: MouseEventHandler;
  dialogData: { name: N; props?: DialogProps<TriggerDialogMap[N]> } & {
    name: "";
    props?: object;
  };
}) {
  if (!dialogData.props) return null;

  if (dialogData.name === DialogName.bookmark)
    return <Bookmark closeDialog={closeDialog} {...dialogData.props} />;
  if (dialogData.name === DialogName.share)
    return <Share closeDialog={closeDialog} {...dialogData.props} />;
  if (dialogData.name === DialogName.moreTopics)
    return <MoreTopics closeDialog={closeDialog} />;
  if (dialogData.name === DialogName.viewableVideo)
    return <ViewableVideo closeDialog={closeDialog} />;
  if (dialogData.name === DialogName.comment)
    return <Comment closeDialog={closeDialog} />;
  if (dialogData.name === DialogName.download)
    return <Download closeDialog={closeDialog} />;

  return null;
}

const AppDialog = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({
    name: null,
    props: {},
  });

  const closeDialog = () => {
    setDialogOpen(false);
  };
  const openDialog = (e) => {
    setDialogOpen(true);
    if (e.detail) {
      setDialogData(e.detail);
    }
  };

  useEffect(() => {
    onDialogEvent(openDialog);

    return () => offDialogEvent(openDialog);
  }, []);

  return (
    <>
      <Transition appear show={isDialogOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-20 overflow-y-auto"
          onClose={closeDialog}
        >
          <div className="min-h-screen px-4 text-center" id="close-dialog">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="my-8 inline-block w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-center align-middle shadow-xl transition-all">
                <Content dialogData={dialogData} closeDialog={closeDialog} />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

const DIALOG_EVENT = "dialog:open";
const onDialogEvent = (callback: EventListener) =>
  document.addEventListener(DIALOG_EVENT, callback);

const offDialogEvent = (callback: EventListener) =>
  document.removeEventListener(DIALOG_EVENT, callback);

function triggerDialog<N extends DialogName>(
  name,
  props: TriggerDialogMap[N] = {}
) {
  const event = new CustomEvent(DIALOG_EVENT, {
    detail: {
      name,
      props,
    },
  });
  document.dispatchEvent(event);
}

export { triggerDialog };
export default AppDialog;
